<template>
  <div class="permissions">
    <router-view />

    <confirm-modal
      @decline="$modal.hide('confirm')"
    >
      <template #decline>
        Нет, оставить
      </template>

      <template #confirm>
        Да, удалить
      </template>
    </confirm-modal>
  </div>
</template>

<script>
import ConfirmModal from '@/components/Common/ConfirmModal.vue'

export default {
  components: {
    ConfirmModal
  }
}
</script>
