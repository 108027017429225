<template>
  <modal
    :name="modalName"
    height="auto"
    @before-open="onBeforeOpen"
    @before-close="$emit('close')"
  >
    <div class="confirm-modal">
      <close-button
        v-if="!isConfirmLoading"
        class="confirm-modal__close"
        @click="$modal.hide(modalName)"
      />

      <strong class="confirm-modal__text">
        <template v-if="title">
          {{ title }}
        </template>

        <slot
          v-else
          name="text"
        >
          Вы точно хотите уйти без сохранения изменений?
        </slot>
      </strong>

      <div class="confirm-modal__buttons">
        <template v-if="declinePrimary">
          <x-btn
            color="gray"
            class="confirm-modal__confirm"
            :loading="isConfirmLoading"
            @click="onConfirm"
          >
            <slot name="confirm">
              Уйти
            </slot>
          </x-btn>

          <x-btn
            :disabled="isConfirmLoading"
            class="confirm-modal__decline"
            @click="$emit('decline')"
          >
            <slot name="decline">
              Остаться
            </slot>
          </x-btn>
        </template>

        <template v-else>
          <x-btn
            :disabled="isConfirmLoading"
            color="gray"
            class="confirm-modal__decline"
            @click="$emit('decline')"
          >
            <slot name="decline">
              Нет
            </slot>
          </x-btn>
          <x-btn
            class="confirm-modal__confirm"
            :loading="isConfirmLoading"
            @click="onConfirm"
          >
            <slot name="confirm">
              Да
            </slot>
          </x-btn>
        </template>
      </div>
    </div>
  </modal>
</template>

<script>
import CloseButton from '@/components/Interface/CloseButton.vue'

export default {
  components: {
    CloseButton
  },

  props: {
    declinePrimary: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      modalName: 'confirm',
      title: '',
      confirmCallback: null,
      isConfirmLoading: false
    }
  },

  methods: {
    onBeforeOpen (e) {
      const { title, onConfirm } = e.params || {}
      if (title) {
        this.title = title
      }
      if (onConfirm) {
        this.confirmCallback = onConfirm
      }
    },

    async onConfirm () {
      this.isConfirmLoading = true

      if (this.confirmCallback) {
        await this.confirmCallback()
      } else {
        this.$emit('confirm')
      }

      this.isConfirmLoading = false
    }
  }
}
</script>

<style lang="stylus" scoped>
  .confirm-modal
    display flex
    flex-direction column
    padding 46px 80px

    &__text
      text-align center
      font-weight $font-weights.bold
      font-size 20px
      color #2c2c37

    &__buttons
      width 100%
      display flex
      justify-content space-between
      margin-top 20px

    &__confirm,
    &__decline
      width 48%
</style>
